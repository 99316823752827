













































































import { StandardCategory } from '@/domain/category/standard/model/StandardCategory';
import { CategoryLabel } from '@/domain/category/label/model/CategoryLabel';
import StandardCategoryCreate from '@/domain/category/standard/views/StandardCategoryCreate.vue';
import { CommonState } from '@frientrip/domain';
import { StandardCategoryService } from '@/domain/category/standard/service/StandardCategoryService';
import { apolloClient } from '@/apolloClient';
import { ToggleButton } from 'vue-js-toggle-button';
import { ContainerMixin } from '@/common/mixin/containerMixin';

const standardCategoryService = new StandardCategoryService(apolloClient);

export default ContainerMixin.extend({
  name: 'StandardCategoryPerDepthList',
  components: {
    StandardCategoryCreate,
    ToggleButton,
  },
  props: {
    categoriesInfo: {
      type: Object,
    },
    depth: {
      type: Number,
    },
    categories: {
      type: Object,
    },
    parentCategoryName: {
      type: String,
    },
  },
  data() {
    return {
      searchedCategoryLabels: [] as CategoryLabel[],
      selectedLabel: { id: '', code: '', name: '' } as CategoryLabel,
      showCategoryLabelSearchModal: false,
      showErrorMessageTime: 0,
      errorMessage: '',
      toggleButtonState: false,
      activeIndex: -1,
    };
  },
  computed: {
    // categories(): StandardCategory[] {
    //   return this.categoriesInfo.categories;
    // },
    parentId(): string {
      return this.categories.parentId;
    },
  },
  methods: {
    setActive(index: number): void {
      this.activeIndex = index;
    },
    selectCategory(category: StandardCategory, index: number): void {
      this.setActive(index);
      this.$emit('addChildren', category);
    },
    showModal(): void {
      console.log(this.categoriesInfo);
      this.showCategoryLabelSearchModal = true;
    },
    closeModal(standardCategories: StandardCategory[]): void {
      this.showCategoryLabelSearchModal = false;
      this.$emit('sync', standardCategories);
    },
    selectLabel(categoryLabel: CategoryLabel) {
      console.log('selectLabel, : ', categoryLabel);
      this.selectedLabel = categoryLabel;
    },
    searchLabels(categoryLabels: CategoryLabel[]) {
      this.searchedCategoryLabels = categoryLabels;
    },
    isActive(category: StandardCategory): boolean {
      return category.status == CommonState.ACTIVE;
    },
    async activateCategory(category: StandardCategory): Promise<void> {
      await standardCategoryService.activateStandardCategory(category.id);
      category.status = CommonState.ACTIVE;
      this.toggleButtonState = false;
    },

    async inactivateCategory(category: StandardCategory): Promise<void> {
      await standardCategoryService.inactivateStandardCategory(category.id);
      category.status = CommonState.INACTIVE;
      this.toggleButtonState = false;
    },
    async changeStatusCategory(
      category: StandardCategory
    ): Promise<void | boolean> {
      if (this.depth !== 1) {
        let isActivate = true;
        await this.$emit(
          'checkCategoryStatus',
          this.parentId,
          this.depth,
          (x: boolean) => {
            return (isActivate = x);
          }
        );

        if (!isActivate) {
          this.showErrorAlert({ message: '상위 카테고리를 활성화 해주세요.' });
          return false;
        }
      }

      this.toggleButtonState = true;
      category.status === CommonState.ACTIVE
        ? await (this as any).inactivateCategory(category)
        : await (this as any).activateCategory(category);
      this.$emit('changeStatus', category);
    },
  },
});
