import gql from 'graphql-tag';

export const STANDARD_CATEGORY_LIST_QUERY = gql`
  query standardCategories(
    $filter: StandardCategoryFilter
    $page: Int
    $size: Int
  ) {
    category {
      standards(page: $page, size: $size, filter: $filter) {
        edges {
          node {
            id
            label {
              id
              code
              name
            }
            status
            depth
            seqNo
            children {
              id
              status
              label {
                id
                code
                name
              }
              depth
              seqNo
              children {
                id
                status
                label {
                  id
                  code
                  name
                }
                depth
                seqNo
                children {
                  id
                  status
                  label {
                    id
                    code
                    name
                  }
                  depth
                  seqNo
                  children {
                    id
                    status
                    label {
                      id
                      code
                      name
                    }
                    depth
                    seqNo
                  }
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;
