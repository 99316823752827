













































































































import Vue from 'vue';
import { CategoryLabel } from '@/domain/category/label/model/CategoryLabel';
import CategoryLabelCreate from '@/domain/category/label/views/CategoryLabelCreate.vue';
import { StandardCategoryService } from '@/domain/category/standard/service/StandardCategoryService';
import { apolloClient } from '@/apolloClient';
import { STANDARD_LEAVES } from '@/components/Category/queries/query';
import { StandardCategory } from '@/common/model/Category';
import { LeafCategoryFilter } from '@/domain/category/model/LeafCategoryFilter';
import { ApolloQueryResult } from 'apollo-client';
import {
  StandardCategoryAPIResponse,
  StandardCategoryConnection,
  StandardCategoryEdge,
} from '@/domain/category/standard/model/StandardCategory';
import { CategoryType } from '@/domain/category/model/CategoryType';

interface StandardLeavesParam {
  page: number;
  size: number;
  filter: LeafCategoryFilter;
}

const standardCategoryService = new StandardCategoryService(apolloClient);

export default Vue.extend({
  name: 'StandardCategoryCreate',
  components: {
    CategoryLabelCreate,
  },
  props: {
    parentCategoryId: {
      type: String,
    },
    categoryName: {
      type: String,
    },
    depth: {
      type: Number,
    },
  },
  data() {
    return {
      isSelected: false,
      searchedCategoryLabelName: '',
      selectedCategoryLabel: { id: '', code: '', name: '' } as CategoryLabel,
      searchedCategoryLabels: [] as CategoryLabel[],
      showErrorMessageTime: 0,
      errorMessage: '',
      page: 1,
      size: 100,
      currentParentCategories: [],
      parentCategoriesInfo: [],
      categoryType: CategoryType.STANDARD,
      disableAddButton: false,
    };
  },
  computed: {
    isExposureCategoryLabelCreateForm(): boolean {
      return (
        this.searchedCategoryLabelName.length > 0 &&
        this.searchedCategoryLabels.length == 0 &&
        this.selectedCategoryLabel.id == ''
      );
    },
    searchCategoryVisualArea(): { display: string } {
      if (this.searchedCategoryLabelName.length === 0) {
        return { display: 'none' };
      } else {
        return { display: 'block' };
      }
    },
  },
  methods: {
    searchCategoryLabelName(inputName: string): void {
      this.searchedCategoryLabelName = inputName;

      //검색 값이 비어있을 경우에는 초기화 해주는게 맞을듯...
      if (this.searchedCategoryLabelName.length === 0) {
        this.searchedCategoryLabels = [];
        this.selectedCategoryLabel = {
          id: '',
          code: '',
          name: '',
        };
      }
    },
    selectCategoryLabel(categoryLabel: CategoryLabel): void {
      this.selectedCategoryLabel = categoryLabel;
      this.isSelected = true;
    },
    deSelectCategoryLabel(): void {
      this.selectedCategoryLabel = {
        id: '',
        code: '',
        name: '',
      };
    },
    searchCategoryLabels(categoryLabels: CategoryLabel[]) {
      console.log('searchCategoryLabels categoryLabels, : ', categoryLabels);
      this.searchedCategoryLabels = categoryLabels;
    },
    countDownChanged(showErrorMessageTime: number): void {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    async createCategory(): Promise<void> {
      this.disableAddButton = true;
      try {
        let parentId = this.parentCategoryId;
        let categoryList: StandardCategory[] = [];
        for (let i = this.depth; i <= 4; i++) {
          const standardCategory =
            await standardCategoryService.createStandardCategory({
              parentId,
              categoryLabelCode: this.selectedCategoryLabel.code,
            });

          parentId = standardCategory.id;
          categoryList.push(standardCategory);
        }

        await this.$emit('closeModal', categoryList);

        this.$notify({
          title: '카테고리 등록 성공',
          message: '카테고리가 등록되었습니다.',
          type: 'success',
          position: 'bottom-right',
        });
      } catch (e) {
        console.error(e);
        this.disableAddButton = false;
        this.$notify({
          title: '카테고리 등록 실패',
          message: e.message.replace('GraphQL error:', ''),
          type: 'warning',
          position: 'bottom-right',
        });
      }
    },
    showErrorMessage(message: string): void {
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },
    closeModal(standardCategory: StandardCategory): void {
      this.$emit('closeModal', standardCategory);
    },
  },
  apollo: {
    currentParentCategories: {
      query: STANDARD_LEAVES,
      variables(): StandardLeavesParam {
        return {
          page: this.page,
          size: this.size,
          filter: {
            likeLabelNameIncludePath: this.categoryName,
          },
        };
      },
      debounce: 200,
      result(
        result: ApolloQueryResult<{
          category: { standardLeaves: StandardCategoryConnection };
        }>
      ) {
        const categories = result.data.category.standardLeaves.edges
          .map((edge: StandardCategoryEdge) => {
            let category: StandardCategoryAPIResponse | undefined = edge.node;
            let path = [];
            let categoryIds = [];

            while (category) {
              path.unshift(category.label.name);
              categoryIds.unshift(category.id);
              category = category.parent;
            }

            return {
              id: edge.node.id,
              path: path.join(' > '),
              paths: path,
              ids: categoryIds,
            };
          })
          .filter(category => {
            return category.ids.includes(this.parentCategoryId);
          });

        const currentCategoryInfo = [];
        for (let i = 0; i < this.depth; i++) {
          const info = {
            depth: i + 1,
            categoryName: categories[0].paths[i],
            id: categories[0].ids[i],
          };

          currentCategoryInfo.push(info);
        }

        this.$data.parentCategoriesInfo = currentCategoryInfo;
      },
      update: data => data.category.standardLeaves,
      skip(): boolean {
        return this.categoryName === '';
      },
    },
  },
});
