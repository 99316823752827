













































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import { STANDARD_CATEGORY_LIST_QUERY } from '@/domain/category/standard/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  StandardCategory,
  StandardCategoryConnection,
  StandardCategoryEdge,
} from '@/domain/category/standard/model/StandardCategory';
import { CategoryFilter } from '@/domain/category/model/CategoryFilter';
import { CategoryRequestPram } from '@/domain/category/model/CategoryRequestPram';
import StandardCategoryPerDepthList from '@/domain/category/standard/views/StandardCategoryPerDepthList.vue';
import _ from 'lodash';
import { CommonState } from '@frientrip/domain';

interface CategoriesInfo {
  categories?: StandardCategory[];
  parentId?: string;
  depth: number;
  parentCategoryName?: string;
}

interface Option {
  value: string;
  text: string;
  description: string;
}

export default Vue.extend({
  name: 'StandardCategoryList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    StandardCategoryPerDepthList,
  },
  data() {
    return {
      childCategories: [],

      categories: [],
      secondCategoriesInfo: {} as CategoriesInfo,
      thirdCategoriesInfo: {} as CategoriesInfo,
      fourthCategoriesInfo: {} as CategoriesInfo,
      page: this.$route.query.page || 1,
      pageSize: this.$route.query.pageSize || 20,
      filter: {
        ids: null,
        depth: 0,
        parentId: null,
        likeLabelName: null,
      } as CategoryFilter,
      selectCategory: [
        {
          depth: 1,
          categories: [],
        },
        {
          depth: 2,
          categories: [],
        },
        {
          depth: 3,
          categories: [],
        },
        {
          depth: 4,
          categories: [],
        },
      ] as CategoriesInfo[],
      categoryTypes: [],
      selectType: '',
    };
  },
  methods: {
    addChildren(category: StandardCategory) {
      const depth = category.depth + 1;

      if (depth > 4) {
        return;
      }

      this.selectCategory[category.depth] = {
        parentId: category.id,
        depth: depth,
        categories: category.children,
        parentCategoryName: category.label.name,
      };

      this.selectCategory.map((row: CategoriesInfo) => {
        if (depth < row.depth) {
          this.selectCategory[row.depth - 1] = {
            depth: row.depth,
          };
        }
      });

      this.$forceUpdate();
    },
    async getCategory(standardCategories: StandardCategory[]): Promise<void> {
      console.log(standardCategories);
      let firstDetpth = 0;
      let additionParentId = '';
      let currentCategory: StandardCategory[] = [];

      if (standardCategories.length > 0) {
        for (const [index, standardCategory] of standardCategories.entries()) {
          if (index === 0) {
            console.log(standardCategory);
            firstDetpth = standardCategory.depth - 1;
            additionParentId = standardCategory.id;
            this.selectCategory[standardCategory.depth - 1].categories!.push(
              standardCategory
            );

            currentCategory = this.selectCategory[firstDetpth].categories!;
            continue;
          }

          const parentCategory = currentCategory.find(
            (category: StandardCategory) => {
              console.log(category);
              return category.id === additionParentId;
            }
          );

          if (!parentCategory) {
            return;
          }

          if (parentCategory?.children !== undefined) {
            parentCategory?.children.push(standardCategory);
          } else {
            parentCategory.children = [standardCategory];
          }

          currentCategory = parentCategory.children;
          additionParentId = standardCategory.id;
        }
      }

      await this.$apollo.queries.getCategories.refetch();

      this.$forceUpdate();
    },
    selectedOptionStyle(option: Option): void | string {
      if (this.selectType === option.value) {
        return 'selected';
      }
    },
    typeSelect(option: Option): void {
      this.initCategoryType();
      this.selectType = option.value;
      const selectTypeCategoriesInfo = this.$data.categories.filter(
        (category: StandardCategory) => {
          return category.id === option.value;
        }
      )[0];

      this.selectCategory[0] = {
        parentId: selectTypeCategoriesInfo.id,
        depth: 1,
        categories: selectTypeCategoriesInfo.children,
        parentCategoryName: selectTypeCategoriesInfo.label.name,
      };
    },
    initCategoryType(): void {
      this.selectCategory = [
        {
          depth: 1,
          categories: [],
        },
        {
          depth: 2,
          categories: [],
        },
        {
          depth: 3,
          categories: [],
        },
        {
          depth: 4,
          categories: [],
        },
      ];
    },
    async changeStatus(category: StandardCategory): Promise<void> {
      const status = category.status;
      console.log(category);
      const changeCategory = this.selectCategory[
        category.depth - 1
      ].categories!.find((row: StandardCategory) => {
        return category.id === row.id;
      });

      if (changeCategory?.children) {
        changeCategory.children.map((row: StandardCategory) => {
          let cloneChildren: StandardCategory[] = [];
          let visitedChildren: StandardCategory[] = [];
          row.status = status;
          cloneChildren = _.toArray(row.children);

          while (cloneChildren.length > 0) {
            const visit: StandardCategory | undefined = cloneChildren.shift();

            if (!visit) {
              return;
            }

            visit.status = status;

            if (!visitedChildren.includes(visit)) {
              visitedChildren.push(visit);
              if (visit.children) {
                cloneChildren = [...cloneChildren, ...visit.children];
              }
            }
          }
        });
      }

      // if (this.selectCategory[category.depth].categories) {
      //   this.selectCategory[category.depth].categories!.map(
      //     (row: StandardCategory) => {
      //       row.status = status;
      //     }
      //   );
      // }

      await this.$apollo.queries.getCategories.refetch();

      this.$forceUpdate();
    },
    async checkCategoryStatus(
      parentId: string,
      depth: number,
      isActivateFunc: (x: boolean) => boolean
    ): Promise<void> {
      if (!this.selectCategory[depth - 2].categories) {
        isActivateFunc(false);
      }

      const parentCategory = this.selectCategory[depth - 2].categories!.find(
        (standardCategory: StandardCategory) => {
          return standardCategory.id === parentId;
        }
      );

      isActivateFunc(parentCategory!.status === CommonState.ACTIVE);
    },
  },
  apollo: {
    getCategories: {
      query: STANDARD_CATEGORY_LIST_QUERY,
      variables(): CategoryRequestPram {
        return {
          page: Number(this.page),
          size: Number(this.pageSize),
          filter: this.filter,
        };
      },
      error(e: ApolloError): void {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          category: { standards: StandardCategoryConnection };
        }>
      ) {
        const categoryTypeArray: Option[] = [];
        this.$data.categories = result.data.category.standards.edges.map(
          (edge: StandardCategoryEdge) => {
            categoryTypeArray.push({
              value: edge.node.id,
              text: edge.node.label.name,
              description: '',
            });
            return edge.node;
          }
        );

        this.$data.categoryTypes = categoryTypeArray;
      },
      fetchPolicy: 'network-only',
      update: data => {
        return data.category.standards.edges.map(
          (edge: StandardCategoryEdge) => {
            return edge.node;
          }
        );
      },
    },
  },
});
